import {applyMiddleware, combineReducers, compose, createStore} from '@reduxjs/toolkit';
import thunk from "redux-thunk";
import monitorReducerEnhancer from "./enhancers/monitorReducer";
import loggerMiddleware from './middleware/logger';

export default function configureAppStore(inisialState) {
    let reducers = combineReducers({
        // TODO: add reducers here
    });

    let enhancements = [applyMiddleware(thunk, loggerMiddleware), monitorReducerEnhancer];

    if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancements.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(reducers, inisialState, compose(...enhancements));
}
