import React from 'react';
import {render} from 'react-dom'
import {Provider} from 'react-redux';
import App from './App';
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import configureStore from './redux/store';
import * as serviceWorker from './utils/serviceWorker';

const store = configureStore();

const renderApp = () =>
    render(
        <React.StrictMode>
            <Provider store={store}>
                <App/>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root')
    )

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

renderApp();
