import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';


export default class NavBar extends React.Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                <Container>
                    <Navbar.Brand href="/">Welborn</Navbar.Brand>
                        <Nav>
                            <Nav.Item>
                                <Nav.Link href="/">Home</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/next">Next</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link href="/prev">Prev</Nav.Link>
                            </Nav.Item>
                        </Nav>
                </Container>
            </Navbar>
        )
    }
}