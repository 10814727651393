import React from "react";
import {Container} from "react-bootstrap";

export default function Home() {
    return (
        <Container>
            <row>
                <h1>Home</h1>
            </row>
        </Container>
    )
}