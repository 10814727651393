import React from 'react';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import './css/App.css';
import Home from './features/homepage/home';
import NavBar from './features/navBar/navBar';


const Next = () => {
    return (
        <div>
            <h1>Next</h1>
        </div>
    )
}

const Prev = () => {
    return (
        <div>
            <h1>Previous</h1>
        </div>
    )
}

function App() {
    return (
        <div className="App">
            <NavBar/>
            <Router>
                <header className="App-header">
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path="/next" element={<Next/>}/>
                        <Route path="/prev" element={<Prev/>}/>
                    </Routes>
                </header>
            </Router>
        </div>
    );
}

export default App;
